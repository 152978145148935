import React, { useMemo } from 'react';
import { useQuery } from 'utils/react-query';
import classnames from 'classnames';

import LinkButton from 'components/uiLibrary/LinkButton';
import Typography from 'components/uiLibrary/Typography';
import Loader from 'components/uiLibrary/Loader';
import PreviewCard from 'components/Globals/PreviewCard';
import EntityCard from 'components/Globals/EntityCard';

import queries from 'containers/Artist/queries';

import { TP, ENTITY_TYPE } from 'constants/index';

import usePageContext from 'utils/hooks/usePageContext';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import ImpressionTracker from 'utils/components/impressionTracker';

import { useTranslation } from 'src/i18n';

import classes from './FeaturedArtists.module.scss';
import ClaimProfileCard from '../ClaimProfileCard';

const ArtistCard = ({ artist, large, preload, openNewTab, trackingData }) => {
  const { height, width, typographyProps } = useMemo(
    () => ({
      height: large ? 259 : 148,
      width: large ? 175 : 100,
      typographyProps: {
        name: {
          size: large ? 14 : 11,
        },
        subtext: {
          size: large ? 12 : 9,
        },
      },
    }),
    [large],
  );

  if (!artist) {
    return null;
  }

  const entityDataToTrack = {
    entityId: artist?.id,
    entityName: artist?.name,
    entityType: ENTITY_TYPE.ARTIST,
    ...trackingData,
  };
  return (
    <ImpressionTracker data={entityDataToTrack}>
      <EntityCard
        entityType={ENTITY_TYPE.ARTIST}
        entity={artist}
        height={height}
        width={width}
        preload={preload}
        typographyProps={typographyProps}
        featured
        openNewTab={openNewTab}
        trackingData={entityDataToTrack}
      />
    </ImpressionTracker>
  );
};

const FeaturedArtists = ({
  filters = {},
  limit,
  large = false,
  showMoreLink = false,
  openNewTab = false,
  isV4 = false,
  trackingData,
  showMoreLinkTrackingData,
}) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { isMobile } = useDeviceTypeLayouts();
  const { navigate } = usePageContext();
  const { data, isLoading } = useQuery(
    queries.getArtists({
      filters: {
        sort: 'random',
        tier: 'A',
        ...filters,
      },
      limit,
    }),
  );

  if (isLoading) {
    return (
      <div className={classes.root}>
        <Loader />
      </div>
    );
  }

  // Seems like this is not used anymore
  if (isV4) {
    return (
      <div className={classes.featuredArtists}>
        {data?.data?.map(artist => (
          <PreviewCard key={artist?.id} entity={artist} entityType={ENTITY_TYPE.ARTIST} />
        ))}
        <ClaimProfileCard isVertical />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {data?.data?.map((artist, index) => (
        <ArtistCard
          key={artist?.id}
          artist={artist}
          large={large}
          preload={isMobile ? index < 9 : index < 14}
          openNewTab={openNewTab}
          trackingData={trackingData}
        />
      ))}
      {!isLoading && showMoreLink && (
        <LinkButton
          styles={{
            root: classnames(classes.artistCard, {
              [classes.large]: large,
            }),
          }}
          {...navigate.getLinkProps({ entityType: ENTITY_TYPE.ARTIST, onlyLinkProps: true })}
          variant="native"
          trackingData={showMoreLinkTrackingData}
          isLink
        >
          <div className={classes.moreCard}>
            <Typography color="link" weight="bold">
              {t(`${TP}.FN_VIEW_MORE`)}
            </Typography>
          </div>
        </LinkButton>
      )}
    </div>
  );
};

export default FeaturedArtists;
