import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';
import { useQuery } from 'utils/react-query';

import SectionBlock from 'components/Globals/SectionBlock';
import ParseHtml from 'components/ParseHtml';
import Typography from 'components/uiLibrary/Typography';
import LinkButton from 'components/uiLibrary/LinkButton';
import SelectDropdown from 'components/uiLibrary/Inputs/SelectDropdown';
import Skeleton from 'components/uiLibrary/Loaders/Skeleton';
import InformationReferences from 'components/Globals/Display/InformationReferences';
import { SECTIONS, COMPONENTS } from 'components/Globals/Analytics/constants';
import Content from 'components/Globals/Layout/StubPage/Content';
import orgQueries from 'containers/Organizations/queries';

import { useTranslation } from 'src/i18n';
import useBioDescriptions from 'utils/hooks/useBioDescriptions';
import usePageContext from 'utils/hooks/usePageContext';
import { TP, ENTITY_MAIN_TABS, ENTITY_TYPE } from 'constants/index';

import AssociatedOrgs from '../AssociatedOrgs';
import classes from './AboutOrg.module.scss';

const Stages = ({ entity, t }) => {
  const itemsCount = entity?.stages?.length;
  if (!itemsCount) {
    return null;
  }
  return (
    <SectionBlock
      className={classes.stageSection}
      title={t(`${TP}.FN_VENUE_STAGE_INFO_QUESTION`)}
      count={itemsCount}
      enableSeeAll={false}
    >
      <ul className={classes.stageList}>
        {entity.stages.map(item => (
          <li key={item.id} className={classes.stageItem}>
            <Typography size="14" italic>
              {item.name}
            </Typography>
            <Typography size="14">{item.capacity ?? '--'}</Typography>
          </li>
        ))}
      </ul>
    </SectionBlock>
  );
};

const AboutInfo = ({ bioContent, references, showMinDescription, linkProps, trackingData, language }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { navigate } = usePageContext();
  const Wrapper = showMinDescription ? LinkButton : Fragment;
  const [isTruncated, setIsTruncated] = useState(false);
  const bioContentRef = useRef(null);
  const handleLinkProps = () => {
    navigate.to(linkProps);
  };

  useEffect(() => {
    const { current } = bioContentRef;
    if (current && showMinDescription) {
      const isOverflowing = current.scrollHeight > current.clientHeight;
      setIsTruncated(isOverflowing);
    }
  }, [bioContentRef, showMinDescription]);

  return (
    <Wrapper
      {...(showMinDescription && {
        onClick: handleLinkProps,
        variant: 'text',
        styles: { root: classes.about },
        trackingData: { ...trackingData, component: COMPONENTS.CONTENT },
      })}
    >
      <div
        ref={bioContentRef}
        className={classnames(classes.bioText, { [classes.clampedContent]: showMinDescription })}
      >
        <ParseHtml lang={language}>{bioContent}</ParseHtml>
        {isTruncated && (
          <Typography color="secondary" className={classes.readMoreLink}>
            ...{t(`${TP}.FN_READ_MORE`)}
          </Typography>
        )}
        <InformationReferences references={references} />
      </div>
    </Wrapper>
  );
};

// TODO: Complete revamped need to be done on this component as is has same features as entityAbout
const AboutOrganization = ({ entity, showMinDescription = false, entityType, sectionProps = {} }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { navigate } = usePageContext();

  const { data: bioData, isFetching } = useQuery(
    orgQueries.getTranslations({
      id: entity?.id,
      queryConfig: {
        enabled: entityType === ENTITY_TYPE.ORGANIZATION && !!entity?.stats?.about?.exists,
        select: response => ({
          ...response,
          data: response?.data?.filter(item => item?.content || item?.description),
        }),
      },
    }),
  );

  const { onChangeLang, references, chosenLang, bioContent, langOptions, isLoading, language } = useBioDescriptions({
    bioData,
    isFetching,
  });
  const linkProps = navigate.getLinkProps({
    entity,
    entityType,
    path: ENTITY_MAIN_TABS.ABOUT,
  });

  if (isLoading) {
    return <Skeleton variant="rectangular" height={300} />;
  }

  return (
    <SectionBlock
      title={bioContent && t(`${TP}.ABOUT`)}
      withTitle={!!bioContent}
      seeAllTrackingData={{
        section: SECTIONS.ABOUT,
        component: COMPONENTS.SEE_ALL_CTA,
      }}
      {...(bioContent &&
        !showMinDescription && {
          titleChildren: (
            <>
              {langOptions?.length > 1 ? (
                <div className={classes.langDropdownLarge}>
                  <Typography size={12}>{t(`${TP}.FN_AVAILABLE_IN`)}</Typography>
                  <SelectDropdown
                    styles={{
                      select: classes.select,
                      formControl: classes.formControl,
                    }}
                    options={langOptions}
                    value={chosenLang?.value}
                    onChange={onChangeLang}
                    hideBorder
                  />
                </div>
              ) : (
                <div className={classes.singleLanguage}>
                  <Typography size={12}>{t(`${TP}.FN_AVAILABLE_IN`)}</Typography>
                  <Typography size={12} className={classes.singleLanguage__name}>
                    {chosenLang?.language?.name}
                  </Typography>
                </div>
              )}
            </>
          ),
        })}
      {...(showMinDescription && {
        linkProps,
      })}
      sectionProps={sectionProps}
      enableDivider
    >
      <div className={classes.bioSection}>
        {bioContent && (
          <div>
            <div className={classes.bioWrapper}>
              <AboutInfo
                showMinDescription={showMinDescription}
                bioContent={bioContent}
                linkProps={linkProps}
                trackingData={{
                  section: SECTIONS.ABOUT,
                }}
                language={language}
                references={references}
              />
            </div>

            {langOptions?.length && (
              <Typography size={12} color="secondary" italic>
                ({t(`${TP}.FN_AVAILABLE_IN`)}: {langOptions?.map(lang => lang?.language?.name)?.join(', ')})
              </Typography>
            )}
          </div>
        )}
        {!showMinDescription && <Stages entity={entity} t={t} />}
        {!showMinDescription && <AssociatedOrgs entity={entity} entityType={entityType} excludeVenues />}
      </div>
    </SectionBlock>
  );
};

const AboutOrg = ({ entity, showMinDescription = false, entityType, sectionProps = {} }) => {
  const sections = useMemo(() => {
    const components = [
      {
        component: AboutOrganization,
        props: {
          showMinDescription,
          entity,
          entityType,
        },
        sectionProps,
        showSection: true,
      },
    ];

    return components;
  }, [showMinDescription, entity, entityType, sectionProps]);

  return <Content entity={entity} entityType={entityType} sections={sections} />;
};

export default AboutOrg;
