import React from 'react';
import classnames from 'classnames';

import Typography from 'components/uiLibrary/Typography';
import EntityName from 'components/Globals/EntityName';
import LinkButton from 'components/uiLibrary/LinkButton';
import { COMPONENTS, SECTIONS } from 'components/Globals/Analytics/constants';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import useTheme, { THEMES } from 'components/Globals/Layout/ThemeProvider';

import usePageContext from 'utils/hooks/usePageContext';

import {
  TP,
  ENTITY_TYPE,
  PROFILE_TYPES,
  PRIVACY_ATTRIBUTE_PROFILE_TYPES,
  PRIVACY_ATTRIBUTE_IDS,
  PROFILE_TYPE_IDS,
} from 'constants/index';

import { useTranslation } from 'src/i18n';

import classes from './AgencyConnectionCard.module.scss';

const AgencyConnectionCard = ({ entity, index, agencyConnection, isOverview, onClick }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');

  const { permissions } = usePageContext();
  const { theme } = useTheme();
  const isDarkTheme = theme === THEMES.DARK;
  const activeProfileTypeId = permissions?.activeProfile?.profileType?.id;
  const hasCastingToolAccess = permissions?.castingToolPermissions?.hasAccess;
  const showLoginToView = entity?.subscriptionStatus !== PROFILE_TYPES.PRO && !permissions?.isLoggedIn;

  const agencyInfo = agencyConnection?.agency;
  const privacyAttributesIds = agencyConnection?.privacyAttributes?.map(privacy => privacy?.id);
  const trackingData = {
    section: SECTIONS.REPRESENTATION_AND_CONTACT,
    component: COMPONENTS.CONTACT,
    entityId: agencyInfo.id,
    entityName: agencyInfo.name,
    entityType: ENTITY_TYPE.MANAGER,
  };

  const hasPermissionToView =
    hasCastingToolAccess ||
    privacyAttributesIds?.includes(PRIVACY_ATTRIBUTE_PROFILE_TYPES[activeProfileTypeId]) ||
    privacyAttributesIds?.includes(PRIVACY_ATTRIBUTE_IDS.PUBLIC) ||
    (privacyAttributesIds?.includes(PRIVACY_ATTRIBUTE_IDS.FREE_AND_PRO) &&
      [PROFILE_TYPE_IDS.ARTIST, PROFILE_TYPE_IDS.AGENT, PROFILE_TYPE_IDS.AOS].includes(activeProfileTypeId));

  const hasPermissionToViewAgency = hasPermissionToView && !showLoginToView;

  return (
    <LinkButton
      key={agencyConnection?.id}
      variant="text"
      styles={{
        root: isOverview ? classes.agencyLink : classes.agencyItem,
      }}
      onClick={() => onClick(hasPermissionToView, agencyConnection)}
      trackingData={trackingData}
      disableUnderline
    >
      <div className={classes.agencyRow}>
        {!isOverview && (
          <>
            <SpriteIcon className={classes.agencyIcon} icon="agency" size={24} />
            <SpriteIcon className={classes.agencyHoverIcon} icon="agency:hover" size={24} />
          </>
        )}
        <div className={classnames(classes.agencySection, { [classes.agencySectionOverview]: isOverview })}>
          <div className={classnames(classes.agencyCardInfo, { [classes.agencyOverviewInfo]: isOverview })}>
            <Typography
              size={isDarkTheme ? 12 : 14}
              weight="medium"
              className={classes.agencyName}
              color={isDarkTheme ? 'tertiary' : 'primary'}
            >
              {hasPermissionToViewAgency ? (
                <EntityName entity={agencyInfo} entityType={ENTITY_TYPE.MANAGER} isRaw trackingData={trackingData} />
              ) : (
                `${t(`${TP}.M_AGENCY`)} ${index + 1}`
              )}
            </Typography>
            {agencyConnection?.isGM && hasPermissionToViewAgency && (
              <Typography
                className={!isDarkTheme && classes.agreement}
                color={isDarkTheme ? 'tertiary' : 'primary'}
                size="12"
              >
                {`(${t(`${TP}.FN_GENERAL_MANAGEMENT`)})`}
              </Typography>
            )}
            {!agencyConnection?.acknowledged && hasPermissionToViewAgency && (
              <Typography size="12" color="secondary" className={classes.notApproved}>
                {isOverview ? (
                  <>
                    (<SpriteIcon size={13} icon="report_error" /> {t('AGENCY_NOT_CONFIRMED')})
                  </>
                ) : (
                  <>
                    <SpriteIcon size={13} icon="report_error" /> {t('AGENCY_NOT_CONFIRMED')}
                  </>
                )}
              </Typography>
            )}
            {showLoginToView && (
              <Typography
                className={!isDarkTheme && classes.loginToView}
                size={12}
                color={isDarkTheme ? 'tertiary' : 'primary'}
              >
                ({t(`${TP}.FN_LOGIN_TO_VIEW`)})
              </Typography>
            )}
          </div>
          <div className={classnames(classes.ctaSection, { [classes.ctaSectionOverview]: isOverview })}>
            {!isOverview && (
              <Typography size={12} color="secondary" className={classes.ctaTitle}>
                {t('VIEW_CONTACT_REPRESENTATION')}
              </Typography>
            )}
            <SpriteIcon icon="chevron_right" size={16} className={classes.rightIcon} />
          </div>
        </div>
      </div>
    </LinkButton>
  );
};

export default AgencyConnectionCard;
