import React from 'react';
import times from 'lodash/times';

import Skeleton from 'components/uiLibrary/Loaders/Skeleton';
import SectionBlock from 'components/Globals/SectionBlock';
import InfiniteListPage, { PageContent, PageLoading } from 'components/Globals/Layout/InfiniteListPage';

import queries from 'containers/Productions/queries';

import { TP, ENTITY_MAIN_TABS } from 'constants/index';
import { useTranslation } from 'src/i18n';
import usePageContext from 'utils/hooks/usePageContext';
import { COMPONENTS, SECTIONS } from 'components/Globals/Analytics/constants';

import classes from './EntityRentalsList.module.scss';
import RentalCard from '../RentalCard';

const trackingData = {
  section: SECTIONS.RENTALS_LISTING,
};

const EntityRentalsList = ({ entityType, entity, limit, disabled = false, enableSeeAll, title, isOverview }) => {
  const { t } = useTranslation('NS_DISPLAY_V4');
  const { filterParams, navigate } = usePageContext();

  return (
    <div className={classes.root}>
      <InfiniteListPage
        query={queries.getRentals({ entityType, entityId: entity?.id, filters: filterParams, limit })}
        updateAppliedFilters
        disabled={disabled}
      >
        {({ count }) => (
          <>
            <SectionBlock
              title={title || t(`${TP}.FN_PRODUCTION_AVAILABLE_FOR_RENT`)}
              count={count}
              enableDivider
              enableSeeAll={enableSeeAll}
              {...(enableSeeAll && {
                linkProps: {
                  ...navigate.getLinkProps({ entityType, entity, path: ENTITY_MAIN_TABS.RENTALS }),
                  title: t('VIEW_ALL_PRODUCTION_RENTALS'),
                },
              })}
              enableViewAllButton={isOverview}
              seeAllTrackingData={{ ...trackingData, component: COMPONENTS.SEE_ALL_CTA }}
            >
              <PageContent styles={{ page: classes.page, content: classes.content }}>
                {({ data }) => (
                  <>
                    {data?.map(production => (
                      <RentalCard
                        production={production}
                        trackingData={{ ...trackingData, component: COMPONENTS.RENTAL_CARD }}
                      />
                    ))}
                  </>
                )}
              </PageContent>

              <PageLoading>
                <div className={classes.skeletonLoaders}>
                  {times(3, index => (
                    <Skeleton key={index} variant="rectangle" height="200px" width="342px" />
                  ))}
                </div>
              </PageLoading>
            </SectionBlock>
          </>
        )}
      </InfiniteListPage>
    </div>
  );
};

export default EntityRentalsList;
