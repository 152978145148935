// TODO: Rearrange this file
import { useEffect, useState, useCallback } from 'react';
import head from 'lodash/head';

import { i18n } from 'src/i18n';
import { swap } from 'utils/common';
import { getOption } from 'utils/globals/app';

const useBioDescriptions = ({ bioData, isFetching, withLanguage = true }) => {
  const [chosenLang, setLang] = useState(null);
  const [langOptions, setOptions] = useState([]);
  const [bioContent, setBio] = useState(null);
  const [references, setReferences] = useState([]);

  useEffect(() => {
    if (bioData?.data?.length === 0 || !withLanguage) {
      return;
    }
    const descriptions = bioData?.data;
    const defaultBio = head(descriptions);
    const lang = typeof window === 'undefined' ? bioData?.lang || 'en' : i18n.language;

    const data = descriptions?.filter(val => val.content || val.description);
    const currentLangIndex = data?.findIndex(x => x.language.lang === lang);
    if (currentLangIndex > 0) {
      swap(data, 0, currentLangIndex);
    }
    const langsOptions = data?.map(b => ({ ...b, ...getOption(b?.language, 'id', 'name', { id: b?.language?.id }) }));
    setBio(langsOptions?.[0]?.content || langsOptions?.[0]?.description);
    setReferences(langsOptions?.[0]?.references);
    setOptions(langsOptions);
    setLang(langsOptions?.[0]);

    if (currentLangIndex === -1) {
      setBio(defaultBio?.content || defaultBio?.description);
      setReferences(defaultBio?.references);
    }
  }, [bioData?.data, bioData?.lang, withLanguage]);

  const onChangeLang = useCallback(
    (e, { value }) => {
      const selectedOption = langOptions?.find(option => option?.value === value);
      setLang(selectedOption);
      setBio(selectedOption?.description || selectedOption?.content);
      setReferences(selectedOption?.references);
    },
    [langOptions],
  );

  return {
    onChangeLang,
    chosenLang,
    bioContent,
    isLoading: isFetching,
    langOptions,
    language: chosenLang?.language?.lang,
    hasBio: bioData?.data?.length > 0,
    references,
  };
};

export default useBioDescriptions;
