import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import LinkButton from 'components/uiLibrary/LinkButton';
import Typography from 'components/uiLibrary/Typography';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Image, { TRANSFORMATIONS } from 'components/uiLibrary/Image';
import VideoPlayer from 'components/uiLibrary/VideoPlayer';
import Carousel from 'components/uiLibrary/Carousel';
import EntityName from 'components/Globals/EntityName';
import GallerySpaceFiller from 'components/Globals/Media/GallerySpaceFiller';

import ImpressionTracker from 'utils/components/impressionTracker';
import usePageContext from 'utils/hooks/usePageContext';
import { MEDIA_TYPES_IDS } from 'constants/consts';
import { TP, ENTITY_TYPE, TRACK_CARD_IMPRESSION } from 'constants/index';
import { useTranslation } from 'src/i18n';
import { getEntityAndTypeFromContributor, getProductionDateParts, getProductionMeta } from 'utils/productions';
import { getComposerFromCreators, getComposerName } from 'utils/composer';

import classes from './RentalCard.module.scss';

const RentalCard = ({ production, trackingData = {} }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { navigate } = usePageContext();
  const linkProps = navigate.getLinkProps({
    entity: production,
    entityType: ENTITY_TYPE.PRODUCTION,
    onlyLinkProps: true,
  });

  const {
    mediaList,
    premierDate,
    productionName,
    producedBy,
    directors,
    rentalIncludes,
    composer,
    costume,
  } = useMemo(() => {
    const date = getProductionDateParts(production);

    const { title: productionTitle, producer, costumeDesigner } = getProductionMeta(production);
    const creator = getComposerFromCreators(production?.productionWorks?.[0]?.work?.creators)?.profile;
    const { entityType, entity } = getEntityAndTypeFromContributor(producer);

    const directorsList = production?.directors;

    const filteredMediaList = production?.media?.filter(media => {
      if (media?.mediaType?.id === MEDIA_TYPES_IDS.VIDEO) {
        return true;
      }
      return media?.file && Object.keys(media?.file?.urls)?.length > 0;
    });

    return {
      premierDate: date?.startDate,
      productionName: productionTitle,
      producer: entity?.name,
      directors: {
        name: directorsList?.[0]?.name,
        id: directorsList?.[0]?.id,
        entityType: ENTITY_TYPE.ARTIST,
        total: directorsList?.length - 1,
      },
      rentalIncludes: production?.rentals?.[0]?.rentalIncludes,
      composer: creator,
      producedBy: {
        name: entity?.name,
        id: entity?.id,
        entityType,
      },
      costume: {
        name: costumeDesigner?.[0]?.profile?.name,
        total: costumeDesigner?.length - 1,
        id: costumeDesigner?.[0]?.profile?.id,
        entityType,
      },
      mediaList: filteredMediaList,
    };
  }, [production]);

  return (
    <ImpressionTracker
      data={{
        entityId: production?.id,
        entityName: production?.slug,
        entityType: ENTITY_TYPE.PRODUCTION,
        ...trackingData,
      }}
      className={classes.impressionCard}
      entity={ENTITY_TYPE.PRODUCTION}
    >
      <LinkButton
        {...linkProps}
        disableUnderline
        disableHover
        isLink
        variant="text"
        styles={{ root: classes.rentalCard }}
        trackingData={{
          ...trackingData,
          entityId: production?.id,
          entityName: production?.slug,
          entityType: ENTITY_TYPE.PRODUCTION,
        }}
      >
        <div className={classnames(classes.imageWrapper)}>
          {mediaList?.length > 0 ? (
            <Carousel options={{ maxVisibleItems: 1 }}>
              {mediaList?.map(media => {
                if (media?.mediaType?.id === MEDIA_TYPES_IDS.VIDEO) {
                  return <VideoPlayer media={media} width={345} height={194} inline />;
                }

                return (
                  <Image
                    src={media?.file?.urls?.medium}
                    width={343}
                    height={194}
                    transformations={TRANSFORMATIONS.PRODUCTION_MEDIA_SLUG}
                    attribution={{
                      source_info: media?.source_info,
                      credit: media?.credit,
                      copyright: media?.copyright,
                    }}
                    disableNextImage
                  />
                );
              })}
            </Carousel>
          ) : (
            <GallerySpaceFiller text={productionName} />
          )}
        </div>
        <div className={classes.firstLine}>
          <Typography color="secondary" size="12" className={classes.date}>
            {t(`${TP}.FN_PREMIERE_DATE`)}: {premierDate}
          </Typography>
          <Typography size="12" className={classes.viewContact}>
            {t(`${TP}.FN_VIEW_CONTACT`)}
            <SpriteIcon icon="chevron_right" size={12} className={classes.arrowIcon} />
          </Typography>
        </div>
        {productionName && (
          <div className={classes.title}>
            <Typography
              weight="medium"
              variant="span"
              color="primary"
              size="16"
              className={classes.productionName}
              truncate
            >
              {productionName}
              {composer.shortName && ','}
            </Typography>
            {getComposerName(composer) && (
              <Typography variant="span" className={classes.composer} italic color="secondary" truncate>
                <EntityName
                  name={getComposerName(composer)}
                  entityType={ENTITY_TYPE.ARTIST}
                  entity={composer}
                  eventType={TRACK_CARD_IMPRESSION(ENTITY_TYPE.ARTIST)}
                  trackingData={trackingData}
                />
              </Typography>
            )}
          </div>
        )}
        {producedBy && (
          <Typography weight="medium" color="secondary" size="12" className={classes.companyName}>
            <EntityName entity={producedBy} entityType={producedBy?.entityType} isRaw trackingData={trackingData} />
          </Typography>
        )}
        {directors?.name && (
          <Typography size="12" className={classes.cast}>
            {t(`${TP}.FN_DIRECTOR`)}: {directors?.name} {directors?.total > 0 && `+${directors?.total} More`}
          </Typography>
        )}
        {/* TODO: API Missing costume Data in cast crew */}
        {costume?.name && (
          <Typography size="12" className={classes.costumeBy}>
            {t(`${TP}.FN_COSTUMES`)}:{' '}
            <EntityName
              name={costume?.name}
              entityType={costume.entityType}
              entity={costume}
              trackingData={trackingData}
            />{' '}
            {costume?.total > 0 && `+${costume?.total} More`}
          </Typography>
        )}
        {rentalIncludes?.length > 0 && (
          <Typography size="12" className={classes.text} inline>
            {rentalIncludes?.map(item => item?.rentalIncludeType?.name)?.join(', ')} {t(`${TP}.FN_AVAILABLE`)}
          </Typography>
        )}
      </LinkButton>
    </ImpressionTracker>
  );
};

RentalCard.propTypes = {
  production: PropTypes.array,
  className: PropTypes.object,
};

export default RentalCard;
