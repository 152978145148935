import React from 'react';
import { useQuery } from 'utils/react-query';

import queries from 'containers/Artist/queries';

import SectionBlock from 'components/Globals/SectionBlock';
import PreviewCard from 'components/Globals/PreviewCard';
import Loader from 'components/Loader';
import HorizontalScrollIndicators from 'components/uiLibrary/HorizontalScrollIndicators';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import { SECTIONS, COMPONENTS } from 'components/Globals/Analytics/constants';

import usePageContext from 'utils/hooks/usePageContext';
import { useTranslation } from 'src/i18n';

import { TP } from 'constants/index';
import ClaimProfileCard from '../ClaimProfileCard';

import classes from './SimilarProfessions.module.scss';

const trackingData = {
  section: SECTIONS.SIMILAR_PROFESSIONS,
  component: COMPONENTS.ARTIST_CARD,
};

const seeAlltrackingData = {
  ...trackingData,
  component: COMPONENTS.SEE_ALL_CTA,
};

const ArtistSuggestions = ({ entityType, data, isLoggedIn }) => (
  <>
    {data?.map(artist => (
      <PreviewCard key={artist?.id} entity={artist} entityType={entityType} trackingData={trackingData} />
    ))}
    {!isLoggedIn && <ClaimProfileCard isVertical />}
  </>
);

const SimilarProfessions = ({ entity, entityType, sectionProps = {}, limit }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { navigate, permissions } = usePageContext();
  const { isMobile } = useDeviceTypeLayouts();
  const isLoggedIn = permissions?.isLoggedIn;
  const apiLimit = isLoggedIn ? 12 : 11;

  const { data, isLoading } = useQuery(
    queries.getArtistsWithSimilarProfessions({
      id: entity?.id,
      limit: limit || apiLimit,
    }),
  );

  const primaryProfession = entity?.professions?.find(profession => profession?.isPrimary) || entity?.professions?.[0];
  const title = primaryProfession?.profession?.name;
  const slug = primaryProfession?.profession?.slug;

  if (isLoading) {
    return (
      <div className={classes.root}>
        <Loader />
      </div>
    );
  }

  if (!data?.data?.length) {
    return null;
  }

  return (
    <SectionBlock
      title={t(`${TP}.FN_DISCOVER_TYPE`, { type: title })}
      linkProps={{
        ...navigate.getLinkProps({ entityType, onlyLinkProps: true, path: slug }),
        linkTitle: t(`${TP}.FN_DISCOVER_TYPE`, { type: title }),
      }}
      enableSeeAll
      seeAllTrackingData={seeAlltrackingData}
      styles={{ title: classes.title, seeAllLink: classes.seeAllLink, leftBoxIcon: classes.leftBoxIcon }}
      {...sectionProps}
    >
      {!isMobile ? (
        <div className={classes.similarArtists}>
          <ArtistSuggestions entityType={entityType} data={data?.data} isLoggedIn={isLoggedIn} />
        </div>
      ) : (
        <HorizontalScrollIndicators className={classes.horizontalScrollItems} isArrowsEnabled={false}>
          <ArtistSuggestions entityType={entityType} data={data?.data} isLoggedIn={isLoggedIn} />
        </HorizontalScrollIndicators>
      )}
    </SectionBlock>
  );
};

export default SimilarProfessions;
