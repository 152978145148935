import React from 'react';
import Typography from 'components/uiLibrary/Typography';
import ExternalLink from 'components/Globals/ExternalLink';

import { TP } from 'constants/index';

import { useTranslation } from 'src/i18n';

import styles from './InformationReferences.module.scss';

const InformationReferences = ({ references }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  if (!references?.length) {
    return null;
  }
  return (
    <div className={styles.referencesContainer}>
      <Typography size={14} weight="medium">
        {t(`${TP}.FN_SOURCE_INFO`)}:
      </Typography>
      <ul className={styles.references}>
        {references.map((reference, index) => (
          <li key={index}>
            <ExternalLink
              className={styles.reference}
              title={`${t(`${TP}.FN_SOURCE_INFO`)}: ${reference?.url}`}
              url={reference?.url}
            />
            {index < references.length - 1 && (
              <Typography size={12} weight="medium" color="link" className={styles.separator}>
                ;
              </Typography>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default InformationReferences;
