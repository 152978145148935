import React, { useMemo, useState } from 'react';
import useTracking from 'components/Globals/Analytics';
import { GOOGLE_OLD_TRACKING_SERVICES, SECTIONS, COMPONENTS } from 'components/Globals/Analytics/constants';

import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import LinkButton from 'components/uiLibrary/LinkButton';
import Typography from 'components/uiLibrary/Typography';
import ContactDetailsModal from 'components/Globals/EntityContactBlock/ContactDetailsModal';
import EntityRepresentations from 'components/Manager/Display/EntityRepresentations';

import {
  TP,
  ENTITY_MAIN_TABS,
  PROFILE_TYPES,
  PRIVACY_ATTRIBUTE_IDS,
  CONTACT_TYPE,
  ENTITY_TYPE,
  CONTACT_LABEL,
} from 'constants/index';

import usePageContext from 'utils/hooks/usePageContext';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import { useDialogs } from 'utils/hooks/useDialogs';
import { useBuildContacts } from 'utils/contacts';
import { TRACK_EVENTS, CONTACT_NO_ACCESS_IDS } from 'utils/tracking';

import { useTranslation } from 'src/i18n';

import SectionBlock from '../SectionBlock';
import EntitySocialLinks from '../EntitySocialLinks';
import ContactsList from './ContactsList';
import ContactNoAccessModal from './ContactNoAccessModal';

import classes from './EntityContactBlock.module.scss';

const trackingData = {
  section: SECTIONS.REPRESENTATION_AND_CONTACT,
};

const ContactView = ({
  entity,
  contacts,
  boxOfficeContacts,
  showLoginToView,
  entityType,
  onClickHandler,
  setSelectedContact,
}) => (
  <div className={classes.contacts}>
    <EntityRepresentations entityType={entityType} entity={entity} setSelectedContact={setSelectedContact} />
    <ContactsList
      entityType={entityType}
      contacts={contacts}
      showLoginToView={showLoginToView}
      onClickHandler={onClickHandler}
      trackingData={trackingData}
    />
    {entity?.stats?.isPro && <EntitySocialLinks entity={entity} trackingData={trackingData} />}
    {Object.keys(boxOfficeContacts)?.length > 0 && (
      <ContactsList
        entityType={entityType}
        contacts={boxOfficeContacts}
        showLoginToView={showLoginToView}
        onClickHandler={onClickHandler}
        isBoxOffice
      />
    )}
  </div>
);

const ContactOverview = ({
  entity,
  entityType,
  contacts,
  onClickHandler,
  showLoginToView,
  isOverview,
  setSelectedContact,
  isDesktop,
  maxSocialContactCount,
}) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { navigate } = usePageContext();
  const showPaidSection = entity?.stats?.isPro;
  const contactTypes = useMemo(
    () =>
      Object.keys(contacts)?.filter(contactType =>
        [CONTACT_TYPE.PHONE, CONTACT_TYPE.EMAIL, CONTACT_TYPE.WEBSITE].includes(contactType),
      ),
    [contacts],
  );

  if (entityType === ENTITY_TYPE.ORGANIZATION) {
    return (
      <>
        {contactTypes?.map((contactType, index) => (
          <LinkButton
            key={`${contactType}_${index}`}
            variant="text"
            styles={{ root: classes.contactLink }}
            onClick={onClickHandler}
            {...(!showLoginToView && {
              ...navigate.getLinkProps({ entityType, entity, path: ENTITY_MAIN_TABS.CONTACT, isPro: false }),
              isLink: true,
              scroll: true,
            })}
          >
            <Typography size={14} weight="medium">
              <Typography size={14} weight="medium">
                {t(CONTACT_LABEL[contactType])}
                {showLoginToView && (
                  <Typography className={classes.contactSubtitle} size={12}>
                    ({t(`${TP}.FN_LOGIN_TO_VIEW`)})
                  </Typography>
                )}
              </Typography>
            </Typography>
            <SpriteIcon icon="chevron_right" size={16} className={classes.rightArrow} />
          </LinkButton>
        ))}
        <EntityRepresentations
          entityType={entityType}
          entity={entity}
          isOverview={isOverview}
          setSelectedContact={setSelectedContact}
        />
        {isDesktop && contacts[CONTACT_TYPE.SNS]?.length > 0 && showPaidSection && (
          <EntitySocialLinks inline entity={entity} trackingData={trackingData} />
        )}
      </>
    );
  }

  return (
    <>
      <EntityRepresentations
        entityType={entityType}
        entity={entity}
        isOverview={isOverview}
        setSelectedContact={setSelectedContact}
      />
      <EntitySocialLinks
        inline
        showGeneralContact
        entity={entity}
        entityType={ENTITY_TYPE.ARTIST}
        trackingData={trackingData}
        maxSocialContactCount={maxSocialContactCount}
        showSocialLinks={showPaidSection}
      />
    </>
  );
};

const EntityContactBlock = ({ isOverview, sectionProps = {}, maxSocialContactCount }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const track = useTracking();
  const [isOpenContactModal, setIsOpenContactModal] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const [isBoxOfficeContact, setIsBoxOfficeContact] = useState(false);
  const { isDesktop, isMobile } = useDeviceTypeLayouts();
  const { entity, entityType, navigate, permissions } = usePageContext();
  const { setIsLoginDialog } = useDialogs();
  const { isLoggedIn } = permissions;
  const hasCastingToolAccess = permissions?.castingToolPermissions?.hasAccess;
  const activeProfileTypeId = permissions?.activeProfile?.profileType?.id;

  const { generalContacts, boxOfficeContacts } = useBuildContacts({
    contacts: entity?.contacts || [],
    addresses: entity?.addresses || [],
    profileTypeId: activeProfileTypeId,
    hasCastingToolAccess,
  });

  const contactTabLinkProps = useMemo(
    () => navigate.getLinkProps({ entityType, entity, path: ENTITY_MAIN_TABS.CONTACT, isPro: false }),
    [entity, entityType, navigate],
  );
  const showLoginToView = !isLoggedIn && entity?.subscriptionStatus !== PROFILE_TYPES.PRO;

  const onClickHandler = (contact, isBoxOffice) => {
    track.click({
      ...trackingData,
      component: COMPONENTS.ARTIST_DIRECT_CONTACT,
      meta: {
        isBoxOffice,
        hasPermissionToView: contact?.hasPermissionToView,
        showLoginToView,
        contactType: contact?.name,
        contactValue: contact?.value,
      },
    });

    if (showLoginToView) {
      setIsLoginDialog({ isOpen: true });
      return;
    }

    if (contact && !contact?.hasPermissionToView && !isOverview) {
      setSelectedContact({
        ...contact,
        privacyAttributes:
          contact?.privacyAttributes?.length > 1
            ? contact?.privacyAttributes?.filter(privacy => privacy?.id !== PRIVACY_ATTRIBUTE_IDS.CASTING_TOOL)
            : contact?.privacyAttributes,
      });

      track.click(
        {
          name: TRACK_EVENTS.CLICK,
          id: CONTACT_NO_ACCESS_IDS.VIEW,
        },
        GOOGLE_OLD_TRACKING_SERVICES,
      );
      return;
    }

    setIsBoxOfficeContact(isBoxOffice);

    if (!isOverview) {
      setIsOpenContactModal(true);
    }
  };

  const sectionTitle = useMemo(() => {
    if (entityType === ENTITY_TYPE.ARTIST) {
      if (entity.subscriptionStatus === PROFILE_TYPES.PRO) {
        return `${TP}.CONTACT_INFO_SECTION_TITLE`;
      }

      return `${TP}.FN_REPRESENTATION_AND_CONTACT`;
    }

    return `${TP}.m_CONTACT`;
  }, [entity.subscriptionStatus, entityType]);

  return (
    <>
      {isOverview ? (
        <SectionBlock
          title={t(sectionTitle)}
          linkProps={contactTabLinkProps}
          {...sectionProps}
          seeAllTrackingData={{ ...trackingData, component: COMPONENTS.SEE_ALL_CTA }}
        >
          <div className={classes.contactSection}>
            <ContactOverview
              entity={entity}
              entityType={entityType}
              onClickHandler={onClickHandler}
              showLoginToView={showLoginToView}
              isOverview={isOverview}
              setSelectedContact={setSelectedContact}
              contacts={generalContacts}
              isDesktop={isDesktop}
              maxSocialContactCount={maxSocialContactCount}
            />
          </div>
        </SectionBlock>
      ) : (
        <>
          <ContactView
            entity={entity}
            entityType={entityType}
            contacts={generalContacts}
            boxOfficeContacts={boxOfficeContacts}
            showLoginToView={showLoginToView}
            onClickHandler={onClickHandler}
            setSelectedContact={setSelectedContact}
          />
          {isOpenContactModal && (
            <ContactDetailsModal
              entityType={entityType}
              isBoxOffice={isBoxOfficeContact}
              contacts={isBoxOfficeContact ? boxOfficeContacts : generalContacts}
              onClose={() => setIsOpenContactModal(false)}
              trackingData={trackingData}
            />
          )}
        </>
      )}
      <ContactNoAccessModal
        isMobile={isMobile}
        selectedContact={selectedContact}
        onClose={() => setSelectedContact(null)}
      />
    </>
  );
};

export default EntityContactBlock;
