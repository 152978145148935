/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo } from 'react';
import classnames from 'classnames';
import InfiniteListPage, { PageLoading } from 'components/Globals/Layout/InfiniteListPage';
import LinkButton from 'components/uiLibrary/LinkButton';
import SectionBlock from 'components/Globals/SectionBlock';
import EntityName from 'components/Globals/EntityName';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Typography from 'components/uiLibrary/Typography';
import SeparatorList from 'components/uiLibrary/SeparatorList';
import NoResult from 'components/Globals/NoResult';
import { SECTIONS, COMPONENTS } from 'components/Globals/Analytics/constants';
import useTracking from 'components/Globals/Analytics';
import HorizontalScroller from 'components/uiLibrary/HorizontalScroller';
import EntityViewAllCard from 'components/Globals/EntityViewAllCard';

import queries from 'containers/Work/queries';

import { useTranslation } from 'src/i18n';
import usePageContext from 'utils/hooks/usePageContext';

import { TP, ENTITY_TYPE, ENTITY_MAIN_TABS, PERIOD_VALUE, DATE_FORMATS, VALIDATION_STATUSES } from 'constants/index';

import { createDate } from 'utils/date';
import WorkTypeTag from '../WorkTypeTag';
import classes from './CreatorWorkList.module.scss';

const workListingTrackingDataSection = {
  section: SECTIONS.WORK_LISTING,
};
const seeAllWorksTrackingData = {
  ...workListingTrackingDataSection,
  component: COMPONENTS.SEE_ALL_CTA,
};
const workCardTrackingDataSlug = {
  ...workListingTrackingDataSection,
  component: COMPONENTS.WORK_CARD,
};

const WorkCard = ({ work, track }) => {
  const { entity, navigate } = usePageContext();
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const {
    artistProfessions,
    upcomingPerformancesLinkProps,
    pastPerformancesLinkProps,
    linkProps,
    totalMedia,
  } = useMemo(() => {
    const creatorProfessions = work?.creators?.reduce((acc, creator) => {
      if (creator?.profile?.id === entity?.id) {
        acc.push(creator?.profession?.name);
      }

      return acc;
    }, []);
    return {
      artistProfessions: [...new Set(creatorProfessions)],
      totalMedia: work?.stats?.productionCount - (work?.stats?.futureProductionCount || 0),
      upcomingPerformancesLinkProps: navigate.getLinkProps({
        entity: work,
        entityType: ENTITY_TYPE.WORK,
        path: ENTITY_MAIN_TABS.PERFORMANCES,
        queryParams: { section: PERIOD_VALUE.UPCOMING },
      }),
      pastPerformancesLinkProps: navigate.getLinkProps({
        entity: work,
        entityType: ENTITY_TYPE.WORK,
        path: ENTITY_MAIN_TABS.PERFORMANCES,
      }),
      linkProps: navigate.getLinkProps({
        entity: work,
        entityType: ENTITY_TYPE.WORK,
      }),
    };
  }, [work, entity, navigate]);

  const hasProductions = work?.stats?.productionCount > 0;

  return (
    <div
      className={classnames(classes.card, { [classes.disableClick]: !hasProductions })}
      onClick={() => {
        navigate.to(linkProps);
        track.click({
          ...workCardTrackingDataSlug,
          entityId: work?.id,
          entityName: work?.name,
          entityType: ENTITY_TYPE.WORK,
        });
      }}
    >
      <div className={classes.card__header}>
        {work?.premiere?.date && (
          <div>
            <Typography size="12" weight="medium">
              {t(`${TP}.m_PREMIERE`)}
            </Typography>
            <Typography size="12" color="secondary">
              {createDate(work?.premiere?.date).format(DATE_FORMATS.DATE_FULL_LABEL)}
            </Typography>
          </div>
        )}
        <WorkTypeTag tag={work?.workTypes?.[0]?.workType} className={classes.workTypeTag} />
      </div>
      <div className={classes.card__content}>
        <div className={classes.card__content_details}>
          <LinkButton
            styles={{
              root: classes.workName,
            }}
            {...linkProps}
            variant="native"
            isLink
            skipTracking
          >
            <EntityName
              entity={work}
              entityType={ENTITY_TYPE.WORK}
              withComposers
              onlyShortName
              trackingData={workCardTrackingDataSlug}
              typographyProps={{
                work: {
                  size: 12,
                },
                translation: {
                  size: 12,
                },
                composer: {
                  size: 12,
                },
              }}
            />
          </LinkButton>
        </div>
        <div className={classes.professionInfo}>
          <SpriteIcon
            className={classes.professionInfo__validationStatus}
            icon={work?.validationStatus?.id === VALIDATION_STATUSES.APPROVED ? 'red_mask' : 'grey_mask'}
          />
          <Typography size="12" weight="medium">
            <SeparatorList data={artistProfessions} />
          </Typography>
          <div className={classes.professionInfo__icon}>
            {hasProductions && <SpriteIcon size="18" icon="chevron_right" className={classes.icon} />}
          </div>
        </div>
      </div>
      <div className={classes.card__performances}>
        {work?.stats?.futureProductionCount > 0 && (
          <LinkButton
            {...upcomingPerformancesLinkProps}
            styles={{ root: classes.link }}
            variant="text"
            disableUnderline
            stopPropagation
            isLink
          >
            <Typography size="12" color="secondary">
              {work?.stats?.futureProductionCount} {t(`${TP}.m_ARTIST_UPCOMING_PERFOMANCES`)}
            </Typography>
          </LinkButton>
        )}
        {totalMedia > 0 && (
          <LinkButton
            {...pastPerformancesLinkProps}
            styles={{ root: classes.link }}
            variant="text"
            isLink
            stopPropagation
          >
            <Typography
              className={classnames({
                [classes.separator]: work?.stats?.futureProductionCount > 0,
              })}
              size="12"
              color="secondary"
            >
              {totalMedia} {t(`${TP}.FN_LS_AND_VOD`)}
            </Typography>
          </LinkButton>
        )}
      </div>
    </div>
  );
};

const CreatorWorkList = ({
  entity,
  entityType = ENTITY_TYPE.ARTIST,
  limit,
  enableInfiniteScrolling = true,
  sectionProps = {},
  isOverview,
}) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const track = useTracking();

  const { navigate } = usePageContext();

  const creationCount = entity?.stats ? entity?.stats?.creations?.composer?.total : entity?.creationCount;
  const linkProps = useMemo(() => navigate.getLinkProps({ entity, entityType, path: ENTITY_MAIN_TABS.WORKS }), [
    navigate,
    entity,
    entityType,
  ]);

  return (
    <SectionBlock
      title={t(`${TP}.FN_ORIGINAL_WORKS`)}
      count={creationCount}
      {...(isOverview && {
        linkProps: {
          ...linkProps,
          title: t('VIEW_ALL_CTA', { title: t(`${TP}.FN_MUSICAL_WORKS_SECTION_NAME`) }),
        },
        enableSeeAll: true,
      })}
      {...sectionProps}
      seeAllTrackingData={seeAllWorksTrackingData}
      styles={{
        root: classes.sectionBlock,
      }}
    >
      <HorizontalScroller outset>
        <div
          className={classnames(classes.scroller, {
            [classes.scroller__grid]: !isOverview,
          })}
        >
          <InfiniteListPage
            query={queries.getCreatorWorksDetails({ entityId: entity?.id, entityType, limit })}
            disabled={!enableInfiniteScrolling}
          >
            {({ pages, count, isLoading }) => (
              <>
                {!isLoading && count === 0 ? <NoResult title={t(`${TP}.NO_RESULTS`)} /> : null}
                {pages?.map(({ data, total }) => (
                  <>
                    {data?.map(work => (
                      <WorkCard key={work?.id} work={work} track={track} />
                    ))}
                    {isOverview && total > 0 && (
                      <EntityViewAllCard
                        title={t(`${TP}.FN_ORIGINAL_WORKS`)}
                        total={total}
                        linkProps={linkProps}
                        styles={{ root: classes.viewAllCard }}
                      />
                    )}
                  </>
                ))}
                <PageLoading defaultLoader />
              </>
            )}
          </InfiniteListPage>
        </div>
      </HorizontalScroller>
    </SectionBlock>
  );
};

export default CreatorWorkList;
