import React, { useState } from 'react';

import useTracking from 'components/Globals/Analytics';

import { useQuery } from 'utils/react-query';
import times from 'lodash/times';

import Skeleton from 'components/uiLibrary/Loaders/Skeleton';
import SectionBlock from 'components/Globals/SectionBlock';
import AgencyDetailsModal from 'components/Manager/Display/AgencyDetailsModal';
import { GOOGLE_OLD_TRACKING_SERVICES } from 'components/Globals/Analytics/constants';

import queries from 'containers/Managers/queries';
import { transformAgencyDataForDisplay } from 'containers/Managers/transformers';

import { PRIVACY_ATTRIBUTE_IDS, ENTITY_TYPE } from 'constants/index';

import { useTranslation } from 'src/i18n';
import { useDialogs } from 'utils/hooks/useDialogs';
import usePageContext from 'utils/hooks/usePageContext';
import { TRACK_EVENTS, CONTACT_NO_ACCESS_IDS } from 'utils/tracking';

import AgencyConnectionCard from '../AgencyConnectionCard';
import classes from './EntityRepresentations.module.scss';

const EntityRepresentations = ({ entity, isOverview, setSelectedContact, sectionProps = {} }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { entityType, permissions } = usePageContext();
  const [selectedAgency, setSelectedAgency] = useState(null);
  const { setIsLoginDialog } = useDialogs();
  const track = useTracking();
  const showLoginToView = !permissions?.isLoggedIn;

  const { data: agencies, isLoading } = useQuery(
    queries.getEntityManagerRepresentations({
      entityType,
      entityId: entity?.id,
      filters: {
        privacy_attribute_id: [
          PRIVACY_ATTRIBUTE_IDS.PUBLIC,
          PRIVACY_ATTRIBUTE_IDS.CASTING_TOOL,
          PRIVACY_ATTRIBUTE_IDS.AGENCY,
          PRIVACY_ATTRIBUTE_IDS.AOS,
          PRIVACY_ATTRIBUTE_IDS.ARTIST,
          PRIVACY_ATTRIBUTE_IDS.FREE_AND_PRO,
        ],
      },
      queryConfig: {
        enabled: !!entityType && !!entity?.id && entity?.stats?.agencies?.exists,
        select: response => transformAgencyDataForDisplay({ data: response?.data, entityType }),
      },
    }),
  );

  if (
    !entity?.stats?.agencies?.exists ||
    (!isLoading && agencies?.length === 0) ||
    (entityType === ENTITY_TYPE.ARTIST && !entity?.stats?.customizations?.agency?.visibility)
  ) {
    return null;
  }

  const onAgencyClickHandler = (hasPermissionToView, agency) => {
    if (showLoginToView) {
      setIsLoginDialog({ isOpen: true });
      return;
    }

    if (!hasPermissionToView) {
      setSelectedContact({
        ...agency,
        privacyAttributes:
          agency?.privacyAttributes?.length > 1
            ? agency?.privacyAttributes?.filter(privacy => privacy?.id !== PRIVACY_ATTRIBUTE_IDS.CASTING_TOOL)
            : agency?.privacyAttributes,
      });

      track.click(
        {
          name: TRACK_EVENTS.CLICK,
          id: CONTACT_NO_ACCESS_IDS.VIEW,
        },
        GOOGLE_OLD_TRACKING_SERVICES,
      );

      return;
    }

    setSelectedAgency([agency]);
  };

  return (
    <div>
      {isLoading && entity?.stats?.agencies?.exists && (
        <>
          {times(entity?.agencies?.length, index => (
            <Skeleton key={index} variant="rectangular" height={50} />
          ))}
        </>
      )}
      {agencies?.length > 0 && (
        <>
          <div>
            {!isOverview && (
              <div className={classes.agencyHeader}>
                <SectionBlock
                  title={t('AGENCY_ARTIST_MANAGER')}
                  styles={{
                    title: classes.agencyHeaderTitle,
                    header: classes.agencySectionHeader,
                    leftBoxIcon: classes.leftBoxIcon,
                  }}
                  {...sectionProps}
                />
              </div>
            )}
          </div>
          <div className={isOverview ? classes.agencies : classes.agencyContent}>
            {agencies.map((agency, index) => (
              <AgencyConnectionCard
                key={agency.id}
                index={index}
                entityType={entityType}
                agencyConnection={agency}
                isOverview={isOverview}
                onClick={onAgencyClickHandler}
              />
            ))}
          </div>
        </>
      )}
      {selectedAgency?.length > 0 && (
        <AgencyDetailsModal agencies={selectedAgency} onClose={() => setSelectedAgency(null)} />
      )}
    </div>
  );
};

export default EntityRepresentations;
