import React, { useMemo } from 'react';
import { useQuery } from 'utils/react-query';

import Content, { THEMES } from 'components/Globals/Layout/StubPage/Content';
import EntityUpcomingPerformances from 'components/Productions/Display/EntityUpcomingPerformances';
import EntityContactBlock from 'components/Globals/EntityContactBlock';
import EntityPerformanceCredits from 'components/Productions/Display/EntityPerformanceCredits';
import VideoListing, { VIDEO_SECTION_TYPES } from 'components/Media/Display/VideoListing';
import PhotoListing, { PHOTO_SECTION_TYPES } from 'components/Media/Display/PhotoListing';
import FeaturedProductions from 'components/Productions/Display/FeaturedProductions';
import CreatorWorkList from 'components/Work/Display/CreatorWorkList';
import SimilarProfessions from 'components/Artist/Display/SimilarProfessions';
import OnboardingModal from 'components/Globals/Accounts/Common/OnboardingModal';

import { SECTIONS, COMPONENTS, SUB_COMPONENTS } from 'components/Globals/Analytics/constants';
import artistQueries from 'containers/Artist/queries';
import { PROFILE_TYPE_IDS, TP, CONTACT_TYPE } from 'constants/index';
import usePageContext from 'utils/hooks/usePageContext';
import { useTranslation } from 'src/i18n';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';

const TRACKING_DATA_PROPS = {
  MUSICAL_WORK: {
    section: SECTIONS.MUSICAL_WORKS,
  },
  PERFORMANCE_CREDITS: {
    section: SECTIONS.PAST_PERFORMANCES,
    component: COMPONENTS.SEASON_SELECTOR,
  },
  SIMILAR_PROFESSIONS: {
    section: SECTIONS.SIMILAR_PROFESSIONS,
  },
  UPCOMING_PERFORMANCES: {
    section: SECTIONS.UPCOMING_PERFORMANCES,
    component: COMPONENTS.SEASON_SELECTOR,
  },
  OVERVIEW_CREDITS: {
    section: SECTIONS.OVERVIEW_CREDITS,
    component: COMPONENTS.FEATURED_PAST_PERFORMANCES,
    subComponent: SUB_COMPONENTS.PRODUCTION_SLUG,
  },
  AGENCY_REPRESENTATION_INFO: {
    section: SECTIONS.AGENCY_REPRESENTATION_INFO,
  },
  REPRESENTATION_AND_CONTACT: {
    section: SECTIONS.REPRESENTATION_AND_CONTACT,
    component: COMPONENTS.ARTIST_DIRECT_CONTACT,
  },
  DIGITAL_PRESENCE: {
    section: SECTIONS.DIGITAL_PRESENCE,
  },
  FEATURED_VIDEOS: {
    section: SECTIONS.FEATURED_VIDEOS,
    component: COMPONENTS.MEDIA_CARD,
  },
  FEATURED_PHOTOS: {
    section: SECTIONS.FEATURED_PHOTOS,
    component: COMPONENTS.MEDIA_CARD,
  },
  BIOGRAPHY: {
    section: SECTIONS.BIOGRAPHY,
  },
};

const Sections = ({ entity, entityType }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { permissions } = usePageContext();
  const { isMobile } = useDeviceTypeLayouts();
  const showPaidSection = entity?.stats?.isPro;
  const isLoggedIn = permissions?.isLoggedIn;

  const { data: showSimilarArtist = false } = useQuery(
    artistQueries.getArtistsWithSimilarProfessions({
      id: entity?.id,
      limit: isLoggedIn ? 12 : 11,
      queryConfig: {
        select: response => response?.data?.length > 0,
        enabled: !showPaidSection,
      },
    }),
  );

  const sections = useMemo(() => {
    const { stats } = entity || {};
    const isSocialContactsExists = entity?.contacts?.find(contact => contact?.contactType?.slug === CONTACT_TYPE.SNS);

    const components = [
      {
        component: SimilarProfessions,
        props: {
          limit: 6,
        },
        sectionProps: {
          ...(isMobile && { theme: THEMES.DARK }),
        },
        placement: {
          desktop: {
            column: 3,
            order: 1,
          },
          tablet: {
            column: 2,
            order: stats?.productions?.future?.exists ? 2 : 1,
          },
          mobile: {
            column: 1,
            order: 3,
            hide: !stats?.productions?.future?.exists,
          },
        },
        showSection: showSimilarArtist && !showPaidSection,
      },
      {
        component: EntityPerformanceCredits,
        props: {
          infinite: false,
          isOverview: true,
        },
        sectionProps: {
          enableSeeAll: !isMobile,
        },
        placement: {
          desktop: {
            column: 1,
            order: 1,
          },
          tablet: {
            column: 1,
            order: 1,
          },
          mobile: {
            column: 1,
            order: 10,
          },
        },
        showSection: stats?.productions?.past?.exists,
      },
      {
        component: EntityUpcomingPerformances,
        props: {
          infinite: false,
          trackingData: {
            section: SECTIONS.UPCOMING_PERFORMANCES,
            component: COMPONENTS.SEASON_SELECTOR,
            subComponent: SUB_COMPONENTS.ADD_PRODUCTION_CTA,
          },
          isOverview: true,
        },
        sectionProps: {
          enableSeeAll: false,
        },
        placement: {
          desktop: {
            column: 2,
            order: 1,
          },
          tablet: {
            column: 2,
            order: 1,
          },
          mobile: {
            column: 1,
            order: 2,
          },
        },
        showSection: stats?.productions?.future?.exists,
      },
      {
        component: FeaturedProductions,
        props: {
          limit: 5,
          trackingData: TRACKING_DATA_PROPS.OVERVIEW_CREDITS,
        },
        sectionProps: {
          title: t(`${TP}.FN_FEATURED_PAST_PERFORMANCES`),
        },
        placement: {
          desktop: {
            column: 3,
            order: showPaidSection ? 1 : 3,
          },
          tablet: {
            column: 1,
            order: showPaidSection ? 1 : 2,
          },
          mobile: {
            column: 1,
            order: 3,
          },
        },
        showSection: stats?.productions?.past?.exists && stats?.media?.images?.production?.past?.exists,
      },
      {
        component: EntityContactBlock,
        props: {
          isOverview: true,
        },
        sectionProps: {
          marginTopSmall: true,
          enableSeeAll: true,
        },
        placement: {
          desktop: {
            column: 3,
            order: 2,
          },
          tablet: {
            column: 2,
            order: 3,
          },
          mobile: {
            column: 1,
            order: 1,
          },
        },
        showSection:
          !showPaidSection && (stats?.contacts?.exists || entity?.stats?.agencies?.exists || !!isSocialContactsExists),
      },
      {
        component: VideoListing,
        props: {
          type: stats?.media?.videos?.featured?.exists ? VIDEO_SECTION_TYPES.FEATURED : VIDEO_SECTION_TYPES.PRODUCTION,
          limit: 3,
          infinite: false,
          title: t('FEATURED_VIDEOS'),
          isOverview: true,
          trackingData: TRACKING_DATA_PROPS.FEATURED_VIDEOS,
        },
        placement: {
          desktop: {
            column: 2,
            order: 2,
          },
          tablet: {
            column: 2,
            order: 5,
          },
          mobile: {
            column: 1,
            order: 7,
          },
        },
        showSection:
          showPaidSection && (stats?.media?.videos?.production?.exists || stats?.media?.videos?.featured?.exists),
      },
      {
        component: SimilarProfessions,
        ...(isMobile && { sectionProps: { theme: THEMES.DARK } }),
        showSection: !showPaidSection,
        placement: {
          desktop: { hide: true },
          tablet: { hide: true },
          mobile: {
            column: 1,
            order: 7,
            hide:
              !stats?.media?.videos?.production?.exists ||
              !(stats?.productions?.past?.exists && stats?.media?.images?.production?.past?.exists),
          },
        },
      },
      {
        component: PhotoListing,
        props: {
          type: stats?.media?.images?.featured?.exists ? PHOTO_SECTION_TYPES.FEATURED : PHOTO_SECTION_TYPES.PRODUCTION,
          limit: 3,
          infinite: false,
          title: t('FEATURED_PHOTOS'),
          isOverview: true,
          trackingData: TRACKING_DATA_PROPS.FEATURED_PHOTOS,
        },
        placement: {
          desktop: {
            column: 2,
            order: 3,
          },
          tablet: {
            column: 2,
            order: 6,
          },
          mobile: {
            column: 1,
            order: 8,
          },
        },
        showSection:
          showPaidSection && (stats?.media?.images?.production?.exists || stats?.media?.images?.featured?.exists),
      },
      {
        component: SimilarProfessions,
        ...(isMobile && { sectionProps: { theme: THEMES.DARK } }),
        showSection: !showPaidSection,
        placement: {
          desktop: { hide: true },
          tablet: { hide: true },
          mobile: {
            column: 1,
            order: 10,
          },
        },
      },
    ];

    return components;
  }, [entity, isMobile, showSimilarArtist, showPaidSection, t]);

  return <Content entity={entity} entityType={entityType} sections={sections} />;
};

const Home = ({ entityType, entity, isPreviewMode = false }) => {
  const { permissions } = usePageContext();

  const sections = useMemo(() => {
    const { stats } = entity || {};

    const components = [
      {
        component: CreatorWorkList,
        props: {
          enableInfiniteScrolling: false,
          limit: 5,
          isOverview: true,
        },
        placement: {
          desktop: {
            column: 1,
            order: 1,
          },
          tablet: {
            column: 1,
            order: 1,
          },
          mobile: {
            column: 1,
            order: 1,
          },
        },
        showSection: stats?.creations?.composer?.exists,
      },
      {
        component: Sections,
        placement: {
          desktop: {
            column: 1,
            order: 2,
          },
          tablet: {
            column: 1,
            order: 2,
          },
          mobile: {
            column: 1,
            order: 2,
          },
        },
        showSection: true,
      },
    ];

    return components;
  }, [entity]);

  return (
    <>
      <Content entity={entity} entityType={entityType} sections={sections} />
      {permissions?.isTargetMyProfile && !isPreviewMode && (
        <OnboardingModal profileId={entity?.id} profileTypeId={PROFILE_TYPE_IDS.ARTIST} />
      )}
    </>
  );
};

export default Home;
