import React from 'react';

import cx from 'src/classnames';
import { useTranslation } from '../../src/i18n';
import { TP } from 'constants/index';
import styles from './Loader.module.scss';

const Loader = ({ helpText, small, color = '#F36E2B', fastAnimation = false, className }) => {
  const { t } = useTranslation();

  return (
    <div className={cx(styles.content, className)}>
      <svg
        className={cx(styles.icon, { [styles.small]: small, [styles.iconWithText]: helpText })}
        viewBox="28 67 140 108"
      >
        <path
          fill="none"
          stroke={color}
          style={{ opacity: 0.4 }}
          strokeMiterlimit="10"
          strokeWidth="8"
          d="m 48.286458,106.11682 49.114343,0.35972 46.657499,0.17073 c 9.70696,0.0355 21.14457,-10.776491 19.59101,-21.542731 -1.17251,-8.125536 -9.08908,-17.066995 -18.50475,-16.253191 -6.11749,0.52874 -10.7879,0.71649 -19.48774,11.801414 -7.41424,9.446874 -16.96921,23.538568 -25.22037,35.888758 -8.251153,12.3502 -15.371887,25.45621 -21.709316,34.32308 -4.691478,6.56397 -11.456662,18.62505 -23.870912,21.98778 -4.238584,1.14813 -9.149491,-0.0965 -12.982171,-2.23997 -3.47784,-1.945 -6.592192,-5.20854 -7.898776,-8.97301 -1.671607,-4.81616 -1.624579,-10.79853 0.820373,-15.272 3.042269,-5.56635 9.167396,-10.79626 15.443137,-11.12056 l 99.401355,0.40249"
        />
        <path
          className={cx(styles.squiggle, { [styles.fastAnimation]: fastAnimation })}
          fill="none"
          stroke={color}
          strokeMiterlimit="10"
          strokeWidth="8"
          d="m 48.286458,106.11682 49.114343,0.35972 46.657499,0.17073 c 9.70696,0.0355 21.14457,-10.776491 19.59101,-21.542731 -1.17251,-8.125536 -9.08908,-17.066995 -18.50475,-16.253191 -6.11749,0.52874 -10.7879,0.71649 -19.48774,11.801414 -7.41424,9.446874 -16.96921,23.538568 -25.22037,35.888758 -8.251153,12.3502 -15.371887,25.45621 -21.709316,34.32308 -4.691478,6.56397 -11.456662,18.62505 -23.870912,21.98778 -4.238584,1.14813 -9.149491,-0.0965 -12.982171,-2.23997 -3.47784,-1.945 -6.592192,-5.20854 -7.898776,-8.97301 -1.671607,-4.81616 -1.624579,-10.79853 0.820373,-15.272 3.042269,-5.56635 9.167396,-10.79626 15.443137,-11.12056 l 99.401355,0.40249"
        />
      </svg>

      {helpText && (
        <div className={styles.text}>
          <div className={styles.textTitle}>{t(`${TP}.PLEASE_WAIT`)}</div>
          <div>{helpText}</div>
        </div>
      )}
    </div>
  );
};

export default Loader;
